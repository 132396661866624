.react-calendar {
  border-radius: 5px;
  font: inherit;
  overflow: hidden;
  padding: 1em;
  border: none !important;
  box-shadow: 0px 25px 40px -5px rgba(16,36,94,0.25);
}
.holiday {
  color: white !important;
  background-color: rgba(255, 0, 0, 0.5) !important;
  border: 1px solid rgb(252, 228, 209) !important;
}
