.container {
  &-social {
    width: 100%;
    display: flex;
    justify-content: center;
    & a {
      margin: 0.5em;
      font-size: 1.8em;
      text-decoration: none;
      color: darkgray;
    }
  }
}

.fa {
  &-globe {
    
    color: #4267b2;
  }
  &-facebook {
    color: #4267b2;
  }
  &-instagram {
    color: transparent;
      background: radial-gradient(
        circle at 30% 107%,
        #fdf497 0%,
        #fdf497 5%,
        #fd5949 45%,
        #d6249f 60%,
        #285aeb 90%
      );
      background: -webkit-radial-gradient(
        circle at 30% 107%,
        #fdf497 0%,
        #fdf497 5%,
        #fd5949 45%,
        #d6249f 60%,
        #285aeb 90%
      );
      background-clip: text;
      -webkit-background-clip: text;
  }
  &-twitter {
    color: #1da1f2;
  }
  &-youtube {
    color: red;
  }
}
