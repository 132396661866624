.container-login {
  width: 120vw;
  height: 100vh;
  background-color: #f5f5f5;
  display: flex;
}
.container-login-creds {
  margin-top: 2%;
  margin-left: 5%;
  height: 90%;
  border: 0px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  box-shadow: -1px -1px 32px -3px rgba(0, 0, 0, 0.384);
}
.container-login-creds-header {
  margin-top: 3em;
  width: 100%;
  text-align: center;
  font-size: 1.2em;
  color: #303f9f;
  font-weight: 400;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-login-creds-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container-login-creds-form-input {
  margin: 0.5em;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.container-login-creds-form-submit {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 0.5em;
}

.container-login-blank-image img {
  width: 8.42em;
}

.container-login-creds-image {
  display: flex;
  align-items: center;
  justify-content: center;
  & img {
    width: 7.42em;
  }
}

@media screen and (max-width: 720px) {
  .container-login-creds {
    height: 100vh;
    width: 100vw;
  }
  .container-login-logo {
    display: none;
  }
}

.container-kjsieit {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 25vh;
  width: 100%;
}
.container-kjsieit-logo1 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 20%;
}
.container-kjsieit-logo1 img {
  height: 5em;
  width: 5em;
}
.container-kjsieit-text {
  height: 90%;
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.container-kjsieit-text a {
  color: blue;
}

.container-login-blank {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  &-image {
    margin: 10% 0 1% 0;
  }

  &-header {
    width: 60%;
    text-align: center;
    margin-bottom: 1%;
    color: #00254e;
    font-weight: bolder;
  }

  &-motto {
    width: 60%;
    text-align: center;
    margin-bottom: 1%;
  }
}

@media screen and (max-width: 720px) {
  .container-kjsieit {
    height: 40vh;
  }
  .container-kjsieit-logo1 {
    width: 50%;
  }
  .container-login-blank-header {
    // width: 100%;
    display: none;
  }
  .container-login-blank-motto {
    display: none;
  }
  .container-login-blank-image {
    display: none;
  }
  .container-login-creds-header {
    margin-top: 0px;
  }
  // .container-kjsieit-logo1 {
  //   display: none;
  // }
}

@media screen and (min-width: 720px) {
  .container-login-creds-image {
    display: none;
  }
}
